import { Routes } from '@angular/router';
import { IsUnauthenticated, IsAuthenticated } from './auth/auth.guard';
import { environment } from '@environments/environment';

// only valid in non-production version
const sampleRoutes = environment.production ? [] : [
  { path: 'sample', loadComponent: () => import('./modules/sample/sample.component').then(mod => mod.SampleComponent) },
]


export const routes: Routes = [
  { path: 'auth', canMatch: [IsUnauthenticated], loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule) },
  { path: 'portal', canMatch: [IsAuthenticated], loadChildren: () => import('./modules/portal/portal.module').then(m => m.PortalModule) },
  ...sampleRoutes,
  { path: 'error/forbidden', loadComponent: () => import('./modules/error/forbidden/forbidden.component').then(mod => mod.ForbiddenComponent) },
  { path: 'error/not-found', loadComponent: () => import('./modules/error/not-found/not-found.component').then(mod => mod.NotFoundComponent) },
  { path: 'error/maintenance', loadComponent: () => import('./modules/error/maintenance/maintenance.component').then(mod => mod.MaintenanceComponent) },
  { path: 'error/internal-server-error', loadComponent: () => import('./modules/error/internal-server-error/internal-server-error.component').then(mod => mod.InternalServerErrorComponent) },
  { path: '**', redirectTo: 'portal', pathMatch: 'full' },
];
