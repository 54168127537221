import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import localeVi from '@angular/common/locales/vi';
import { APP_INITIALIZER, ApplicationConfig, ErrorHandler, LOCALE_ID } from '@angular/core';
import { loadTranslations } from '@angular/localize';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import en from '@locale/messages.en.json';
import vi from '@locale/messages.vi.json';
import { routes } from './app.routes';
import { AuthInterceptor } from './auth/auth.interceptor';
import { AuthService } from './auth/auth.service';
import { ErrorhandlerService } from './shared/errorhandler.service';
import { provideOAuthClient } from 'angular-oauth2-oidc';

export const initializeLanguage = (): Promise<void> | void => {
  const language = localStorage.getItem('language') ?? 'vi';
  if (language && language !== 'vi') {
    loadTranslations(en.translations);
  } else {
    loadTranslations(vi.translations);
  }
}

export const initializeSupportedLocales = () => {
  registerLocaleData(localeVi, 'vi');
  const language = localStorage.getItem('language') ?? 'vi';
  document.documentElement.lang = language;
  return language;
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideHttpClient(withInterceptorsFromDi()),
    provideAnimations(),
    provideOAuthClient(),
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true, deps: [AuthService] },
    { provide: LOCALE_ID, useFactory: initializeSupportedLocales },
    { provide: APP_INITIALIZER, useFactory: initializeLanguage },
    { provide: ErrorHandler, useClass: ErrorhandlerService },
  ]
};
